import React, { useState} from "react"
import Thumbnail from './Thumbnail.js'
import './App.css';
import {Route, Switch} from "react-router-dom";
// import Oceanside from './Oceanside';
// import KiraKids from './KiraKids';
 


function Img(props) {

const [isToggleRaw, switchToggle] = useState(true);

function toggleNav(e){
  e.target.innerHTML === '.raw' ? switchToggle(true) : switchToggle(false);

}

return(
<div>
  {isToggleRaw ?
  <div className="toggle-nav">
  <h1 id="raw" className="active" onClick={toggleNav}>.raw</h1> 
  <h1 id="psd" className="inactive" onClick={toggleNav}>.psd</h1>
  </div>
  :
  <div className="toggle-nav">
  <h1 id="raw" className="inactive" onClick={toggleNav}>.raw</h1>
  <h1 id="psd" className="active" onClick={toggleNav}>.psd</h1>
  </div>
}


  {isToggleRaw ?
  <div className="image-slider" id="slider-raw">


<Thumbnail
    link="/oceanside"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/25449b91699083.5e38c67e05f76.jpg"
    title="Oceanside"
  />
  
  

  <Thumbnail
    link="/san-francisco"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/b2379391699099.5e38c6a0c58cb.jpg"
    title="San Francisco"
  />

<Thumbnail
    link="/new-york"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/77b351132240839.61a547fa0db5a.gif"
    title="New York"
  />



<Thumbnail
    link="/alameda"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/1467c9133731303.61c44bb5168f5.jpg"
    title="Alameda"
  />



  <Thumbnail
    link="/stockholm"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/3e7d0891699091.5e38c68eda444.jpg"
    title="Stockholm"
  />

<Thumbnail
    link="/cupertino-sanjose"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/fadfbd91699111.5e38c6b498f28.jpg"
    title="Cupertino/San Jose"
  />

<Thumbnail
    link="/chicago"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/aafe7b91699097.5e38c6997b7e4.jpg"
    title="Chicago"
  />

<Thumbnail
    link="/j-quelz"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/35bf6691699059.5e38c66169dc8.jpg"
    title="J-Quelz"
  />

<Thumbnail
  link="/ugadi"
  image="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/28a52f94327919.5e7bf36825b22.jpg"
  title="Ugadi"/>


<Thumbnail
    link="/louda-y-los-bad-hombres"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/59690091699051.5e38c6599ac7c.jpg"
    title="Louda y Los Bad Hombres"
  />




  </div>
  : <div className="image-slider" id="slider-psd">
    <Thumbnail
    link="/cal-poly"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/eccef991699007.5e38c6199776a.jpg"
    title="Cal Poly Rec Center"
    category="InDesign, Illustrator, ADA Design"
  />

    
    <Thumbnail
    link="/kira-kids"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/7e017191698973.5e38c5dcb2631.png"
    title="Kira Kids"
    category="Photoshop, Illustrator, ProCreate"
  />
  <Thumbnail
    link="/crush-on-u"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/b9db4693508897.5e66bb6158b01.jpg"
    title="Crush On U"
    category="Photoshop, Illustrator, Photography"
  />

<Thumbnail
    link="/jimmy-dean"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/4cab3591699017.5e38c62212e59.jpg"
    title="Jimmy Dean"  
    category="Photography, Illustrator, Photoshop"    />



<Thumbnail
    link="/AIGA"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/81a98e91699147.5e38c6f681047.png"
    title="AIGA"
    category="Hand-lettering, Photoshop"
  />

<Thumbnail
    link="/tellus"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/6f4f5791699159.5e38c700777c3.jpg"
    title="Tellus 2013"
    category="Paint, Photography, InDesign, Photoshop"
  />

<Thumbnail
    link="/fashioncad"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/938a5491698991.5e9c024be44f3.jpg"
    title="Fashion CAD"
    category="Fashion Sketches, Illustrator, CAD"
  />


<Thumbnail
    link="/mixbook"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/disp/0bc21b91699025.5e38c62d6a16d.jpg"
    title="Mixbook"
    category="Photoshop, Photography, Illustrator"
  />

<Thumbnail
    link="/commodity-of-pop"
    image="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/dd7d4a91699037.5e38c64118c21.jpg"
    title="Commodity of Pop"
    category="Illustrator"
  />







  </div>}
  
  
{/* <Route exact path="/san-jose" component={Oceanside}/>
<Route path="/san-francisco" component={Oceanside}/>
<Route exact path="/chicago" component={Oceanside}/>
<Route path="/stockholm" component={Oceanside}/>
<Route path="/honolulu" component={Oceanside}/> */}



</div>
)
}

export default Img;