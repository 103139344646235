import React from "react"
import './App.css';

function Src(props) {
  return(
    <div className="src-page">


<li className="src-links">
    <a target="_blank" href="https://sonnet-generator.onrender.com/">Sonnet Generator</a>
    <img className="src-pic" src="https://camo.githubusercontent.com/919cfe662d21869f79dff2b8604a9bde63f0cded7333556c4a2859285012c16c/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f686267595a6e496241563043644777446e642f67697068792e676966"/>

    <p className="src-description">
        The Shakespearean Sonnet Generator allows users to read a random sonnet
from Shakespeare's 154 Sonnets or to generate their own acrostic poem.
The goal is to create a fun way to expose readers to Shakespeare's sonnets.</p>
    <p className="tech">Javascript, HTML, CSS, JQuery</p>
    </li>


    <li className="src-links">
    <a target="_blank" href="https://bootstrap-onepager.onrender.com/">Bootstrap OnePager</a>
    <img className="src-pic" src="https://i.imgur.com/UmGM55v.png"/>

    <p className="src-description">
    The Bootstrap OnePager utilizes bootstrap elements like rows, columns, buttons, and form groups to demonstrate responsive design.</p>
    <p className="tech">Bootstrap, HTML, CSS</p>
    </li>


    <li className="src-links">
    <a target="_blank" href="https://dev.to/lydzb123/optimizing-your-postgres-queries-and-server-for-heavy-traffic-1ok1">Optimizing your Postgres queries and server for heavy traffic</a>
    <img className="src-pic" src="https://i.imgur.com/JcsRft3.png"/>
    <p className="src-description">An article on PostgreSQL optimizations</p>

   </li> 

   
<li className="src-links">
    <a target="_blank" href="https://github.com/lydzb123/TwiddlerApp">Twiddler</a>
    <img className="src-pic" src="https://i.imgur.com/M335siA.gif"/>
    <p className="src-description">A re-imagined Twitter experience featuring unique css styling and animations.</p>
    <p className="tech">Html/CSS, JavaScript, JQuery</p>
    </li>



    <li className="src-links">
    <a target="_blank" href="https://github.com/lydzb123/TripGuideReviews/tree/master">Trip Guide App</a>
    <img className="src-pic" src="https://camo.githubusercontent.com/a58aa49bf6403b4f596c3a78c0fc72bfb9ce34ce0d0068fa8bef5b0495064d28/68747470733a2f2f6d69722d73332d63646e2d63662e626568616e63652e6e65742f70726f6a6563745f6d6f64756c65732f6d61785f313230302f3439356538643131303131363336312e356665343039323565356365612e706e67"/>

    <p className="src-description">A dynamic reviews micro-service for a Trip Guide Advisor clone. Worked on a team of engineers to connect our services with a proxy and deploy our application on AWS.
    
</p>
    
<p className="tech">React.JS, JavaScript, Styled-Components, S3, EC2, SQL, Express.JS</p>
    </li>


    <li className="src-links">
    <a target="_blank" href="https://casepoetry.onrender.com/">Case Poetry</a>
    <img className="src-pic" src="https://i.imgur.com/kS5uP8a.png"/>
    <p className="src-description">MVP for a poetry site that allows writers to read and reference a collection of classic poems while simultaneously writing their own notes and works in progress.</p>
    
    <p className="tech">Html/CSS, JavaScript</p>
    </li>



<li className="src-links">
    <a target="_blank" href="https://www.behance.net/gallery/110744691/PuzzleBooth">PuzzleBooth</a>
    <img className="src-pic" src="https://camo.githubusercontent.com/737b6cd3c826dfe74cbeadd9e5101abeed49e576d905b752df8e254617f2a33e/68747470733a2f2f692e696d6775722e636f6d2f365430684569412e676966"/>

    <p className="src-description">
    PuzzleBooth is a web-based game app that combines the likes of Photobooth and a drag-and-drop grid based puzzle.
    </p>
    <p className="tech">JavaScript, HTML/CSS, Canvas</p>
    </li>








    {/* <iframe class="src" src="https://www-ccv.adobe.io/v1/player/ccv/UqZxch2sRtR/embed?api_key=behance1&amp;bgcolor=%23191919" frameborder="0" allowfullscreen="allowfullscreen" class="embed-content" ></iframe>
     <iframe class="src" src="https://www-ccv.adobe.io/v1/player/ccv/DCgsQ_NfxoX/embed?api_key=behance1&bgcolor=%23191919" frameborder="0" allowfullscreen="allowfullscreen" class="embed-content" ></iframe>
     <iframe class="src" src="https://www-ccv.adobe.io/v1/player/ccv/SM3cYYcL4j5/embed?api_key=behance1&bgcolor=%23191919" frameborder="0" allowfullscreen="allowfullscreen" class="embed-content" ></iframe>
     <iframe class="src" src="https://www-ccv.adobe.io/v1/player/ccv/AHiyeU3YShb/embed?api_key=behance1&bgcolor=%23191919" frameborder="0" allowfullscreen="allowfullscreen" class="embed-content" ></iframe>
 */}
     


    </div>
  )
}

export default Src;