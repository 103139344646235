import React from 'react'; // Import the Component component from React
import {Link} from 'react-router-dom'; // Import the Link component
import './App.css';

function Thumbnail(props) {

 
  return (
    <div className="project">
      <Link to={props.link}>
        <div className="project-image">
          <img src={process.env.PUBLIC_URL + props.image} alt="Project Thumbnail"/>
        </div>
        <div className="project-title">{props.title}</div>
        {props.category? <div className="project-category">{props.category}</div> : null}
      </Link>
    </div>
  )
}

export default Thumbnail;