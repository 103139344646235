import React from 'react'; // Import the Component component from React
import {Link} from 'react-router-dom'; // Import the Link component
import './App.css';

function Post(props) {


  return (
    <div className="post">
      <Link to={props.link}>
        <div className="post-title"><h2>{props.title}</h2></div>
        <div className="post-entry"><p>{props.entry}</p></div>
      </Link>
    </div>
  )
}

export default Post;