import React from "react";
import Post from './Post.js'


function Wav () {
  return(
    <div className="wav">
    🎵 Sometimes I make music!
{/* <br></br>
<iframe width="560" height="315" src="https://www.youtube.com/embed/yHyxCuHipy4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

<iframe src="https://open.spotify.com/embed/track/4kZrBToSY5XeJjZCLwU8Qy" width="240" height="320" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
<iframe src="https://open.spotify.com/embed/track/5IsrcGfazh37Q2HxVllMR3" width="240" height="320" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe> 
<iframe src="https://open.spotify.com/embed/track/38X0jHrwQ3UJYTCx4vcgMx" width="240" height="320" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe> */}

    </div>
  )
}

export default Wav;

