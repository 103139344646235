import React, {useState} from "react"
import '../App.css';
import { useHistory } from "react-router-dom";



function ProjectPage(props) {
  
    let history = useHistory();

    function handleClick(e) {
      // if(props.typ ==='raw') {
        history.push("/");
      // }
    }

    const [clickedSource, setSource] = useState(null);
    const [isLightbox, setLightbox] = useState(false);
    const [srcLinks, setSrcLinks] = useState([]);
    const [isFade, toggleFade] = useState(false);

    function handleImageClick(e) {
        setLightbox(true);
        setSource(e.target.src);
    }


    function fadeIn() {
      setTimeout(
        function(){ 
          toggleFade(true);
        }, 3000
      );
    }

    // useEffect() {
    //   setSrcLinks(props.links);
    // //vignette

const links = props.links;

return(
  <div>
  {isFade? <div id="fader"></div> : <div id="fader" className="faderOut"></div> }

  <div className="image-page">

  <div className="image-page-header" onClick={e=>handleClick(e)}>

  <h1 className="previous">Img<span>«</span></h1>
  <h1>{props.name}</h1>
      </div>
      <div className="image-and-lightbox-wrapper">
      {isLightbox ? <div className="lightbox">
          <img className="lightbox-image" src={clickedSource}/>
      </div> : null}
<div className="image-grid">
  {props.links.map(link => (
    <div class="image-container"><img src={link} onClick={ e=> handleImageClick(e)}/></div>
    ))}
</div>
</div>
</div>
</div>

)
}




export default ProjectPage;






