import React from "react"

function Bio(props) {
  return (
    <div>
      <div className="bio-about">
        <h1>Hi, I'm Lydia.<br></br>I'm a software developer/designer,<br></br> and I love-hate the internet.</h1>

        <h2>
          I used to work in marketing design, and that was great for learning all sorts of neat tricks (like how to make GIFs and photoshop stock images to make people want to buy more breakfast sausage).
          Recently, I've ventured into coding, and now that I have more skills in my <a href="#bio-toolkit"><span className="link-underline">toolkit</span></a>, I can finally piece together all the madness that I have ever dreamt of, sans-sausage.<br></br>

          I like words, music, photography, fashion, poetry—all that good stuff. And I fell in love with code for similar reasons. Words are awesome! It's always such a pleasure to say exactly what you want to say.
        </h2>
      </div>

      <div className="bio-section" id="bio-toolkit">
        <h3>// Toolkit</h3>
        <li><b>Front-end</b> | JavaScript (ES5/ES6), React JS, HTML, CSS, Styled Components</li>
        <li><b>Design</b> | Adobe CC, Photoshop, Illustrator, Tableau</li>
        <li><b>Back-end</b> | Node.js, Express, Axios, MySQL, PostgreSQL, MongoDB</li>
        <li><b>Developer &amp; Deployment Tools</b> | Git, Webpack, Babel, Postman, NPM, AWS EC2/S3</li>
        <li><b>Testing</b> | Mocha, Chai</li>
      </div>

      <div className="bio-section">
        <h3>// Education</h3>
        <li><b>Software Engineering Immersive</b> | Hack Reactor @ Galvanize | <i>Jan 2021</i> </li>
        <li><b>Data Analytics Circuit</b> | General Assembly | <i>Aug 2019</i></li>
        {/* <li><b>Accessories Design Course</b> | Milan Fashion Campus | <i>Oct 2014</i> */}
        <li><b>BFA, Art &amp; Design</b> | California Polytechnic State University SLO | <i>Jun 2014</i>
        </li>
        <br></br>
      </div>

      <div className="contact-section">
      <h3>// Contact</h3>
      <li><b><a href="mailto:lydiabaikATgmailDOTcom">Email</a></b></li>
      <li><b><a href="https://www.behance.net/lydiabaik">Behance</a></b></li>
      <li><b><a href="https://www.linkedin.com/in/lydiabaik/">LinkedIn</a></b></li>
      </div>





    </div>

  )
}

export default Bio;