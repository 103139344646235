import React from "react";
import Post from './Post.js'


function Word(props) {
  return(
    <div className="words">
      <Post title="Hope"
        entry={
          `My anger has melted
          My heart has puddled
          So warm and moldable
          And heavy with light
          The fears of could this bes
          and to good to be trues
          had been parading
          Till hope stormed in
          and put up a fight!
          `}
      />

      <Post
        title="From here now"
        entry={
          `I could not bare to leave
          having nothing at all to leave
          To go there then
          I must be from now.
          `}
      />

      <Post
        title="Life is just friction"
        entry={
          `Woman of wonder
          How you marvel in the plain
          You marvel Monday mornings
          See in extraordinary ways

          But what is this life
          Without a slip of a spark
          Citrus slices of serendipidous suddenlies
          To stump me silly
          And shake my little heart

          But my hands are cold
          My heart is cold
          But my feet they burn
          Life is just friction
          As of late

          But my hands are cold
          My heart is cold
          But my feet they burn
          Life is just friction
          As of late

          Woman of wonder
          Rusted still but radiating tall
          Singing my sanity
          I somberly smile

          Fate must be kissed
          To free my frail frame
          Pause triggers that pang
          So I can press play

          But my hands are cold
          My heart is cold
          But my feet they burn
          Life is just friction
          As of late

          But my hands are cold
          My heart is cold
          But my feet they burn
          Life is just friction
          Yeah as of late

          I rush to rewrite
          A reprise
          Tonight
          Lyrical lament
          My surgical flight

          `}
      />

      <Post
        title="Everything"
        entry={
          `I know that
          I don't have to tell them everything
          but I love telling them
          `}
      />

      <Post
        title="A Quiet Whisper"
        entry={
          `Your praise will ever be
          On my hands
          In the way that I drop them towards my lap
          Empty without a front man
          When my silence speaks louder than I could ever sing
          I know that I am becoming more like you
          For all the melodies that once burst forth
          Have been brimming inside of my dead chest cavity
          These are the days that I've longed for, Lord
          How I've longed to just be with you
          To look like you and to talk like you
          Not in quakes or in fires
          But a quiet whisper
          `}
      />

<Post
        title="Clarity"
        entry={
          `Clarity
          More rare
          Than peace
          You give me
          To know
          You know me
          `}
      />

<Post
        title="Daily Quota"
        entry={
          `How do you go back to your daily quota
          after you’ve seen a January tree?
          
          All of its thin oaky branches shoot up
          vertically radial
          into vast open plains of undeniable blue
          
          At the top of its trunk, its most prized possession
          A crown of ruby red palm-sized leaves
          perform the most marvelous foxtrot!
          `}
      />

<Post
        title="Soft Love"
        entry={
          `I want a soft love: I’ve seen it before
          Two friends who look like wool
          Meld together in a rugged world
          
          Solitary dreamers
          Stumble suddenly into divine happenstance
          So dependably
          Becoming and beholding the other
          
          As if they were one
          
          `}
      />

<Post
        title="Contrite"
        entry={
          `One feels a burden
          Yet blesses me so
          One still neglected
          Yet lavished abundantly
          
          When will we silence our hearts
          To hear the song that you’ve been singing
          
          Oh fragile flower
          With a faithful father
          Is your fire with people
          Or is your fire with God
          And does it even matter?
          
          When will your healing come?
          When worship becomes weeping
          Or weeping your worship?
          
          The sacrifices of God are a broken spirit; A broken and a contrite heart, O God, You will not despise.
          `}
      />

<Post
        title="Optional"
        entry={
          `Doting
          Rather
          Slowly
          And
          Unassuredly
          
          Sometimes I look in the mirror
          And think
          Yes
          I completely agree
          
          But right times
          Like ones
          are optional
          As is the reception
          Of every good thing
          We are always free to turn the dial
          Or tunnel vision lavishly
          
          I recently read all the letters that you’ve written me
          And they only make sense
          now that I’m five to ten years
          Removed
          
          I can see the love
          That wasn’t there
          And all the love
          That I couldn’t feel
          Which was very much present
          
          I can finally now receive
          All of your blessings
          And all of your kind words
          I hold dear
          and weep in agreement
          
          I also find resolve
          In the limited vocabularies
          Of hearts I once gave
          Way too much credit to
          
          It’s all the same
          From everywhere
          Actions always speak louder than words
          But words are telling too
          
          Sometimes we are not capable of much more than what we have already given
          But that’s okay too
          We just keep pressing forward
          Leaving behind our deliberately hopeful messy misinterpretations
          And we choose to do better next time
          `}
      />







<br></br>



    </div>
  )
}

export default Word;

